import React, { Fragment, useEffect, useState } from 'react';
import styles from './quizresult.module.css';
import { useParams } from 'react-router-dom';
import withStudentAuth from '../../components/withStudentAuth';
import { authData } from '../../components/getAuth';
import axios from 'axios';
import { toast } from 'react-smart-toaster';
import { Check, Menu, ZoomIn } from 'react-feather';
import { Checkbox, FormControl, FormControlLabel, Radio, RadioGroup, TextField } from '@mui/material';
import * as moment from 'moment';
import Header2 from '../../components/header2';

const QuizResult = () => {
    const auth = authData();
    const { id } = useParams();

    const [quizDetails, setQuizDetails] = useState({});
    const [questionList, setQuestionList] = useState([]);
    const [quizRes, setQuizRes] = useState({});

    useEffect(() => {
        axios.get(process.env.REACT_APP_API_URL + 'student/quiz-details/' + id, {
            headers: { 'x-access-token': auth?.token }
        }).then(response => {
            let quizData = response.data.data;
            if (quizData.length > 0) {
                setQuizDetails(quizData[0]);
            }
        }).catch(error => {
            if (error && error.response && error.response.data && error.response.data.message) {
                toast.error(error.response.data.message);
            } else {
                toast.error("Error occured. Please try again");
            }
        });
    }, [auth?.token, id]);

    useEffect(() => {
        axios.get(process.env.REACT_APP_API_URL + 'student/question-list/' + id, {
            headers: { 'x-access-token': auth?.token }
        }).then(response => {
            setQuestionList(response.data.data);
        }).catch(error => {
            if (error && error.response && error.response.data && error.response.data.message) {
                toast.error(error.response.data.message);
            } else {
                toast.error("Error occured. Please try again");
            }
        });
    }, [auth?.token, id]);

    useEffect(() => {
        axios.get(process.env.REACT_APP_API_URL + 'student/quiz-results/' + id, {
            headers: { 'x-access-token': auth?.token }
        }).then(response => {
            setQuizRes(response.data.data);
        }).catch(error => {
            if (error && error.response && error.response.data && error.response.data.message) {
                toast.error(error.response.data.message);
            } else {
                toast.error("Error occured. Please try again");
            }
        });
    }, [auth?.token, id]);
  
    const handleClick = (qIndex) => {
        let questionListTemp = questionList.map((item,index)=>{
            if(qIndex === index){
                return {...item, isOpen: !item.isOpen };
            }
            return {...item, isOpen:false };
        });
        setQuestionList(questionListTemp);
    };

    return (<React.Fragment>
        <div className={`${styles.background_Color}`}>
        <Header2 />
            <div className={`${styles.Container}`}>
                <div className={`${styles.top_DivSec}`}>
                    <div className={`${styles.mainDivSec_Smalldiv}`}>
                        <div className={`${styles.textArea}`}>
                            <p className={`${styles.classHader}`}>{quizDetails?.class?.title}</p>
                            <p className={`${styles.titleForHeding}`}>{quizDetails?.topic ? quizDetails?.topic?.title : 'All Chapter'}</p>
                            <p className={`${styles.Subject}`}>{quizDetails?.subject?.title}</p>
                            <p className={`${styles.by_Quizzer}`}>By Quizzer</p>
                        </div>
                        <div className={`${styles.group___Ms007}`}>
                            <div className={`${styles.details_AreaClassSec}`}>
                                <p className={`${styles.ClassSecHeading}`}>Board : <span>{quizDetails?.board?.title}</span></p>
                                <div className={`${styles.ClassSecGroupArea}`}>
                                    <img src="/static/img/Vector.svg" className={`${styles.massgLogo}`} alt='' />
                                    <div>
                                        <p className={`${styles.GroupAreaSmallTitel}`}>No: of Questions</p>
                                        <p className={`${styles.GroupAreaBigTitel}`}>{quizDetails?.total_question}</p>
                                    </div>
                                </div>
                                <div className={`${styles.ClassSecGroupArea}`}>
                                    <div className={`${styles.incosCK}`}>
                                        <Check />
                                    </div>
                                    <div>
                                        <p className={`${styles.GroupAreaSmallTitel}`}>Total Marks</p>
                                        <p className={`${styles.GroupAreaBigTitel}`}>{quizDetails?.total_marks}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={`${styles.your_ScoreArea}`}>
                            <p className={`${styles.your_ScoreHeading}`}>Your Score</p>
                            <div className={`${styles.your_ScoreGroup}`}>
                                <p className={`${styles.your_ScoreNum}`}>{quizRes?.total_marks}</p>
                                <p className={`${styles.your_ScoreNumSub}`}>of {quizDetails?.total_marks}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`${styles.MainSec_Area}`}>
                    <div className={`${styles.Row}`}>
                        <div className={`${styles.BigDiv_Sec}`}>
                            <div className={`${styles.BigDiv_Sec_UnderSmL}`}>
                                <div className={`${styles.UnderSmLContGroup}`}>
                                    <p className={`${styles.UnderSmLbigTitle}`}>My Answers</p>
                                </div>
                            </div>
                            <div className={`${styles.BigDiv_Sec_UnderBg}`}>
                                <table className={`${styles.mainTable}`}>
                                    <tr>
                                        <th className={`${styles.th_Heading}`}>No.</th>
                                        <th className={`${styles.th_Heading}`}>Question</th>
                                        <th className={`${styles.th_Heading} ${styles.align_Right}`}>Marks</th>
                                    </tr>
                                    {questionList.map((item, index) => {
                                        return (<Fragment key={index}>
                                            {(quizRes?.answers[index] !== null) && <tr style={{ cursor: "pointer" }} onClick={() => handleClick(index)}>
                                                <td className={`${styles.td_SLNo}`}>{index + 1}</td>
                                                <td>
                                                    <div className={`${styles.td_Group}`}>
                                                        <img src="/static/img/massgicon.svg" alt="" />
                                                        <p className={`${styles.blueTex}`}>{quizDetails?.subject?.title}:</p>
                                                        <p className={`${styles.blackTex}`}>{item?.title}</p>
                                                    </div>
                                                </td>
                                                <td className={`${styles.align_End}`}>
                                                    <div className={`${styles.group_Icon}`}>
                                                        <Menu />
                                                    </div>
                                                    <div className={`${styles.search_Icon}`}>
                                                        <ZoomIn />
                                                        <p className={`${styles.searchTex}`}>View Solution</p>
                                                    </div>
                                                    <p className={`${styles.td_SLNo}`}>{item?.marks}</p>
                                                </td>
                                            </tr>}
                                            {(item?.isOpen) ? (<tr>
                                                <td colSpan={3}>
                                                    <div className={`${styles.halfBig_Div}`}>
                                                        <p className={`${styles.Big_DivHeading}`}>{item?.description}</p>
                                                        {(item?.image) && <img src={item?.image} alt='' style={{maxWidth:'100%', maxHeight: '300px'}} />}
                                                        <div className={`${styles.borderSec}`}></div>
                                                        <div>
                                                            {(item.type === 'Single Select' || item.type === 'True/False' || item.type === 'Muliple Select') && <FormControl>
                                                                <RadioGroup>
                                                                    {item?.options?.map((opt, ind) => {
                                                                        let className = `${styles.ansLabel}`;
                                                                        if((item.type === 'Single Select' || item.type === 'True/False')){
                                                                            if(opt?.is_correct){
                                                                                className += ` ${styles.correctAnsLabel}`;
                                                                            }
                                                                            if(quizRes?.answers[index]?.answer === ind && !quizRes?.answers[index]?.is_correct){
                                                                                className += ` ${styles.wrongAnsLabel}`;
                                                                            }
                                                                        }
                                                                        if((item.type === 'Muliple Select')){
                                                                            if(opt?.is_correct){
                                                                                className += ` ${styles.correctAnsLabel}`;
                                                                            }
                                                                            if(quizRes?.answers[index]?.answer.indexOf(ind) > -1 && !opt?.is_correct){
                                                                                className += ` ${styles.wrongAnsLabel}`;
                                                                            }
                                                                        }
                                                                        
                                                                        return (<>
                                                                            {(item.type === 'Single Select' || item.type === 'True/False') && <FormControlLabel value={ind} control={<Radio />} checked={(quizRes?.answers[index]?.answer === ind)} label={opt?.title} key={ind} className={className} />}
                                                                            {(item.type === 'Muliple Select') && <FormControlLabel value={ind} control={<Checkbox checked={(quizRes?.answers[index]?.answer.indexOf(ind) > -1 ? true : false )} />} label={opt?.title} key={ind} className={className} />}
                                                                            {(opt?.image) && <img src={opt?.image} alt='' style={{maxWidth:'200px', maxHeight: '100px', marginLeft: '30px'}} />}
                                                                        </>);
                                                                    })}
                                                                </RadioGroup>
                                                            </FormControl>}
                                                            {item?.type === 'Subjective' && <div style={{'width':'600px'}}>
                                                                <p>Answer {quizRes?.answers[index]?.is_evalute === 0 && <span style={{padding: '4px 8px', borderRadius: '40px', background: '#FFE1D8', fontSize: '11px', 'color': '#FF4C00', fontWeight: '500', margin: 0, marginBottom: '8px'}}>Need Evaluate</span>}{(quizRes?.answers[index]?.is_evalute === 1 && quizRes?.answers[index]?.is_correct) && <span style={{padding: '4px 8px', borderRadius: '40px', background: '#FFE1D8', fontSize: '11px', 'color': '#FF4C00', fontWeight: '500', margin: 0, marginBottom: '8px'}}>Correct</span>}{(quizRes?.answers[index]?.is_evalute === 1 && !quizRes?.answers[index]?.is_correct) && <span style={{padding: '4px 8px', borderRadius: '40px', background: '#FFE1D8', fontSize: '11px', 'color': '#FF4C00', fontWeight: '500', margin: 0, marginBottom: '8px'}}>Not Correct</span>}</p>
                                                                <TextField id="outlined-multiline-static" fullWidth multiline minRows={4} disabled placeholder="Write your answer" value={quizRes?.answers[index]?.answer?quizRes?.answers[index]?.answer:''}  />
                                                            </div>}
                                                        </div>
                                                        {(item?.remark || item?.remark_image) && <div className={`${styles.remarkArea}`}>
                                                            <p className={`${styles.Headingremark}`}>Remark</p>
                                                            <p className={`${styles.subHeadingremark}`}>{item?.remark}</p>
                                                            {(item?.remark_image && item?.remark_image !== '') && <img src={item?.remark_image} alt='' style={{ maxWidth:'100%'}} />}
                                                        </div>}
                                                    </div>
                                                </td>
                                            </tr>) : null}
                                        </Fragment>);
                                    })}
                                </table>
                            </div>
                        </div>
                        <div className={`${styles.SmallDiv_Sec}`}>
                            <div className={`${styles.SmallDiv_Sec_UnderBg}`}>
                                <div className={`${styles.group_Brod}`}>
                                    <p className={`${styles.SmallDivHeading}`}>Analysis</p>
                                </div>
                                <ul className={`${styles.UnderBg_CostUL}`}>
                                    <li>
                                        <p className={`${styles.UnderBg_CostTitle}`}>Total Correct</p>
                                        <p className={`${styles.UnderBg_CostNo}`}>{quizRes?.total_correct}</p>
                                    </li>
                                    <li>
                                        <p className={`${styles.UnderBg_CostTitle}`}>Total Unattempted</p>
                                        <p className={`${styles.UnderBg_CostNo}`}>{quizRes?.total_questions - quizRes?.total_attemted}</p>
                                    </li>
                                    <li>
                                        <p className={`${styles.UnderBg_CostTitle}`}>Hard Question Answered</p>
                                        <p className={`${styles.UnderBg_CostNo}`}>{quizRes?.total_hard_attemted}</p>
                                    </li>
                                    <li>
                                        <p className={`${styles.UnderBg_CostTitle}`}>Medium Question Answered</p>
                                        <p className={`${styles.UnderBg_CostNo}`}>{quizRes?.total_medium_attemted}</p>
                                    </li>
                                    <li>
                                        <p className={`${styles.UnderBg_CostTitle}`}>Easy Question Answered</p>
                                        <p className={`${styles.UnderBg_CostNo}`}>{quizRes?.total_easy_attemted}</p>
                                    </li>
                                    <li>
                                        <p className={`${styles.UnderBg_CostTitle}`}>Time</p>
                                        <p className={`${styles.UnderBg_CostNo}`}>{(moment.duration(quizRes?.total_time, 'seconds').hours() > 0) ? moment.duration(quizRes?.total_time, 'seconds').hours().toString().padStart(2,'0') : '00'}:{(moment.duration(quizRes?.total_time, 'seconds').minutes() > 0) ? moment.duration(quizRes?.total_time, 'seconds').minutes().toString().padStart(2,'0') : '00'}:{(moment.duration(quizRes?.total_time, 'seconds').seconds() > 0) ? moment.duration(quizRes?.total_time, 'seconds').seconds().toString().padStart(2,'0') : '00'}</p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </React.Fragment>)
}

export default withStudentAuth(QuizResult);